<template>
  <BaseWindow
    width="1000"
    height="850"
    :show="true"
    :showCloseButton="false"
    :preventClickOutside="true"
  >
    <template #title>
      <div class="update-title-outer">
        <div class="update-title-text">
          {{ $t("updatedNotes.title") }}
        </div>
      </div>
    </template>
    <template>
      <div class="update-window">
        <div class="content">
          <template v-for="(note, index) in $t('updatedNotes.notes')">
            <div v-if="note[0] !== '<'" class="dot" :key="`d-${index}`"></div>
            <span
              :class="getRealPlatform !== 'web' ? 'mobile' : ''"
              class="list"
              v-html="note"
              :key="`p-${index}`"
            ></span>
          </template>
        </div>
        <div class="bottom">
          <button
            type="button"
            class="ui-button ui-button-green"
            @click="updateNotesClose"
          >
            {{ $t("general.okay") }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import AnimatedNumber from "@/components/Utils/AnimatedNumber";
import { mapGetters } from "vuex";

export default {
  name: "UpdateNotesWindow",
  components: {
    BaseWindow,
    AnimatedNumber,
  },
  data() {
    return {
      show: false,
      amount: 0,
      animate: false,
    };
  },
  computed: {
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
    }),
  },
  methods: {
    updateNotesClose() {
      this.$store.commit("modals/hide", "updateNotesWindow");
      // Object.values(APP.storageKeys.slots).forEach(async (item) => {
      //   await StorageManager.instance.remove({ key: item });
      // });
      // location.reload();
    },
  },
};
</script>

<style lang="scss">
.update-title-outer {
  position: relative;
  .update-title-text {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-shadow: 0 3px 1px black;
    text-transform: uppercase;
  }
}

.update-window {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .content {
    height: 88%;
    width: 100%;
    padding: 45px 0 0 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .dot {
      width: 20px;
      height: 20px;
      position: relative;
      display: block;
      right: -7px;
      top: 30px;
      background-color: rgba($color: #e08e30, $alpha: 1);
      border-radius: 10px;
    }
    .list {
      width: 97%;
      font-size: 34px;
      line-height: 36px;
      display: flex;
      text-shadow: 0 3px 10px black;
      position: relative;
      left: 32px;
      color: #ffffff;
      .center {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
        .title {
          color: #ffffff;
          font-size: 42px;
        }
      }
    }
    .mobile {
      font-size: 38px !important;
      line-height: 40px !important;
    }
  }
  .bottom {
    height: 12%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.win-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 56px;
  margin-top: -100px;
  text-shadow: 0 3px 1px black;
}
</style>
